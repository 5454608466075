import CyberPanel from '../../cyber-panel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useState, useEffect } from 'react';
import { createMerc } from '../../../api/callApi'

const MercsCreate = ({ launchRefresh }) => {

    const [nickname, setNickname] = useState("")
    const [legalAge, setLegalAge] = useState("")
    const [eddies, setEddies] = useState(0)
    const [callApi, setCallApi] = useState(false)
    const [info, setInfo] = useState("")

    useEffect(() => {
        if (callApi) {
            const merc = {
                nickname: nickname,
                legalAge: legalAge,
                eddies: eddies
            }
            createMerc(merc, setInfo, launchRefresh)
            setCallApi(false)
        }
    }, [callApi, nickname, legalAge, eddies, setCallApi, launchRefresh])


    return (
        <CyberPanel content={
            <Grid container justify={"center"} spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField id="nickname" required label="Nickname" variant="outlined" fullWidth value={nickname} onChange={e => setNickname(e.target.value)} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField id="age" required label="Age" variant="outlined" fullWidth value={legalAge} onChange={e => setLegalAge(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField id="eddies" required label="Starting eddies" variant="outlined" fullWidth value={eddies} onChange={e => setEddies(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <button className={"cyber-button-2"} onClick={e => setCallApi(true)}> Declare a new merc</button>
                </Grid>
                <Grid item xs={12}>
                    <p className="info">{info}</p>
                </Grid>
            </Grid>
        }></CyberPanel>
    )
}


export default MercsCreate
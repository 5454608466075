const LaunchMissionReport = ({ data }) => {
    return (
        <div>
            <h1 className="cyber-panel-legend">Report</h1>
            <div className="cyber-panel report-bg">
                <div className="content-2 report">
                    {data.map((reportLine, index) => <p key={'report-' + index}> {reportLine.description} </p>)}
                </div>
            </div>
        </div>
    )
}

export default LaunchMissionReport
import CyberPanel from '../../cyber-panel';
import Grid from '@material-ui/core/Grid';
import LaunchMissionJobs from '../launch-mission-jobs';
import LaunchMissionReport from '../launch-mission-report';
import LaunchMissionMercs from '../launch-mission-mercs';
import { useState, useEffect } from 'react';
import { getJobs, getMercs, launchMission } from '../../../api/callApi'


const Store = () => {

    const [mercs, setMercs] = useState([])
    const [jobs, setJobs] = useState([])
    const [idMercSelected, setIdMercSelected] = useState(null)
    const [idJobSelected, setIdJobSelected] = useState(null)
    const [callApi, setCallApi] = useState(true)
    const [callApiLaunch, setCallApiLaunch] = useState(false)
    const [report, setReport] = useState({ data: ["No data"] })
    const [info, setInfo] = useState("")

    useEffect(() => {
        if (callApi) {
            getJobs(null, setInfo, setJobs)
            getMercs(null, setInfo, setMercs)
            setCallApi(false)
        }
    }, [callApi, setInfo, setMercs, setJobs, setCallApi])

    useEffect(() => {
        if (callApiLaunch) {
            if(idMercSelected && idJobSelected){
                launchMission(idJobSelected, idMercSelected, setInfo, setReport, setCallApi)
            }
            else {
                setInfo("Uncomplete selection")
            }
            setCallApiLaunch(false)
        }
    }, [callApiLaunch, setCallApiLaunch, idJobSelected, idMercSelected, setInfo, setReport, setCallApi])

    const displayMercs = () => {
        let html = []
        mercs.forEach(merc => {
            if (merc.isAlive) {
                html.push(<Grid key={"launch-merc-" + merc.id} item xs={11}><LaunchMissionMercs setSelect={setIdMercSelected} id={merc.id} nickname={merc.nickname} legalAge={merc.legalAge} idWeapon={merc.idWeapon} weaponName={merc.weaponName} eddies={merc.eddies} isAlive={merc.isAlive} isSelected={idMercSelected === merc.id ? true : false}></LaunchMissionMercs></Grid>)
            }
        })
        if (html.length === 0) {
            html = <Grid item xs={11}><CyberPanel content={<p className={"info"}>No mercenary alive...</p>}></CyberPanel></Grid>
        }
        return html
    }

    const displayJobs = () => {
        let html = []
        jobs.forEach(job => {
            if (job.isAvailable) {
                html.push(<Grid key={"launch-weapon-" + job.id} item xs={11}><LaunchMissionJobs setSelect={setIdJobSelected} id={job.id} fixer={job.fixer} title={job.title} description={job.description} henchmenCount={job.henchmenCount} reward={job.reward} isAvailable={job.isAvailable} isSelected={idJobSelected === job.id ? true : false}></LaunchMissionJobs></Grid>)
            }
        })
        if (html.length === 0) {
            html = <Grid item xs={11}><CyberPanel content={<p className={"info"}>No job available...</p>}></CyberPanel></Grid>
        }
        return html
    }


    return (
        <CyberPanel legend={"Launch Mission"} content={
            <div className="content">
                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={5}>
                        <CyberPanel legend={"Mercs"} content={
                            <div className="content-2">
                                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                                    {displayMercs()}
                                </Grid>
                            </div>
                        }></CyberPanel>
                    </Grid>
                    <Grid item xs={12} md={2} >
                        <button className={"cyber-button-2"} onClick={e => setCallApiLaunch(true)}> Launch Mission</button>
                        <p className="info">{info}</p>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <CyberPanel legend={"Jobs"} content={
                            <div className="content-2">
                                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                                    {displayJobs()}
                                </Grid>
                            </div>
                        }></CyberPanel>
                    </Grid>
                    <Grid item xs={11}>
                        <LaunchMissionReport data={report.data}></LaunchMissionReport>
                    </Grid>
                </Grid>
            </div>
        }>
        </CyberPanel>
    )
}

export default Store
import CyberPanel from '../../cyber-panel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';


const JobsData = ({ id, fixer, title, description, henchmenCount, reward, isAvailable }) => {
    return (
        <CyberPanel legend={"#" + id} content={
            <Grid container spacing={2} className={isAvailable ? "" : "old-job-bg"}>
                <Grid item xs={12}>
                    <TextField disabled label="Title" variant="outlined" defaultValue={title} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextField disabled label="Fixer" variant="outlined" defaultValue={fixer} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextField multiline={true} disabled label="Description" variant="outlined" defaultValue={description} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField disabled label="Henchmen Count" variant="outlined" defaultValue={henchmenCount} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField disabled label="Reward" variant="outlined" defaultValue={reward} fullWidth />
                </Grid>
            </Grid>
        }></CyberPanel>
    )
}


export default JobsData
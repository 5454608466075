import CyberPanel from '../../cyber-panel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useState, useEffect } from 'react';
import { createWeapon } from '../../../api/callApi'

const WeaponsCreate = ({ launchRefresh }) => {

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [damage, setDamage] = useState("")
    const [accuracy, setAccuracy] = useState("")
    const [firerate, setFirerate] = useState("")
    const [price, setPrice] = useState("")
    const [callApi, setCallApi] = useState(false)
    const [info, setInfo] = useState("")

    useEffect(() => {
        if (callApi) {
            const weapon = {
                name: name,
                description: description,
                damage: damage,
                accuracy: accuracy,
                firerate: firerate,
                price: price
            }
            createWeapon(weapon, setInfo, launchRefresh)
            setCallApi(false)
        }
    }, [callApi, name, description, damage, accuracy, firerate, price, setInfo, setCallApi, launchRefresh])

    return (
        <CyberPanel content={
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField required label="Name" variant="outlined" fullWidth value={name} onChange={e => setName(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField required multiline={true} label="Description" variant="outlined" fullWidth value={description} onChange={e => setDescription(e.target.value)} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField required label="Damage" variant="outlined" fullWidth value={damage} onChange={e => setDamage(e.target.value)} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField required label="Accuracy" variant="outlined" fullWidth value={accuracy} onChange={e => setAccuracy(e.target.value)} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField required label="Firerate" variant="outlined" fullWidth value={firerate} onChange={e => setFirerate(e.target.value)} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField required label="Price" variant="outlined" fullWidth value={price} onChange={e => setPrice(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <button className={"cyber-button-2"} onClick={e => setCallApi(true)}> Declare a new weapon</button>
                </Grid>
                <Grid item xs={12}>
                    <p className="info">{info}</p>
                </Grid>
            </Grid>
        }></CyberPanel>
    )
}


export default WeaponsCreate
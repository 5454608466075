import CyberPanel from '../../cyber-panel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';


const LaunchMissionJobs = ({ id, fixer, title, description, henchmenCount, reward, isAvailable, isSelected, setSelect }) => {

    return (
        <CyberPanel isSelected={isSelected} legend={"#" + id} content={
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField disabled label="Title" variant="outlined" value={title} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextField disabled label="Fixer" variant="outlined" value={fixer} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextField multiline={true} disabled label="Description" variant="outlined" value={description} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField disabled label="Henchmen Count" variant="outlined" value={henchmenCount} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField disabled label="Reward" variant="outlined" value={reward} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <button className={"cyber-button-2"} onClick={e => setSelect(id)}> Select this job</button>
                </Grid>
            </Grid>
        }></CyberPanel>
    )
}


export default LaunchMissionJobs
import CyberPanel from '../../cyber-panel';
import Grid from '@material-ui/core/Grid';


const Error404 = () => {
    return (
        <CyberPanel legend={"Error 404 : Not Found"} content={
            <div className="content">
                <Grid container spacing={0}>
                    <Grid item xs={12} md={8}>
                        <img alt="Johnny's gif to help you in your quest." className="error-gif" src="https://media.giphy.com/media/jVAt83ieT49H6ja5Ty/giphy.gif"></img>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <blockquote className="msg-received">You don't belong here V! </blockquote>
                        <blockquote className="msg-received">We have no time to spare here Samuraï, stop slacking we have a life to save.</blockquote>
                        <blockquote className="msg-received">Listen to me, I already told you... I wanted to save you!</blockquote>
                        <blockquote className="msg-response">Shut the f*** up Johnny, I know what I have to do!</blockquote>
                    </Grid>
                </Grid>
            </div>
        }>
        </CyberPanel>
    )
}

export default Error404
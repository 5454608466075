import CyberPanel from '../../cyber-panel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const StoreWeapons = ({ id, name, description, damage, accuracy, firerate, price, isSelected, setSelect }) => {

    return (
        <CyberPanel isSelected={isSelected} legend={"#" + id} content={
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField disabled label="Name" variant="outlined" defaultValue={name} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextField disabled multiline={true} label="Description" variant="outlined" defaultValue={description} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField disabled label="Damage" variant="outlined" defaultValue={damage} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField disabled label="Accuracy" variant="outlined" defaultValue={accuracy} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField disabled label="Firerate" variant="outlined" defaultValue={firerate} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField disabled label="Price" variant="outlined" defaultValue={price} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <button className={"cyber-button-2"} onClick={e => setSelect(id)}> Select this weapon</button>
                </Grid>
            </Grid>
        }></CyberPanel>
    )
}


export default StoreWeapons
import CyberPanel from '../../cyber-panel';
import Grid from '@material-ui/core/Grid';
import StoreWeapons from '../store-weapons';
import StoreMercs from '../store-mercs';
import { useState, useEffect } from 'react';
import { getMercs, getWeapons, buyWeapon } from '../../../api/callApi';

const Store = () => {

    const [mercs, setMercs] = useState([])
    const [weapons, setWeapons] = useState([])
    const [idMercSelected, setIdMercSelected] = useState(null)
    const [idWeaponSelected, setIdWeaponSelected] = useState(null)
    const [callApi, setCallApi] = useState(true)
    const [callApiBuy, setCallApiBuy] = useState(false)
    const [info, setInfo] = useState("")

    useEffect(() => {
        if (callApi) {
            getMercs(null, setInfo, setMercs)
            getWeapons(null, setInfo, setWeapons)
            setCallApi(false)
        }
    }, [callApi, setInfo, setMercs, setWeapons, setCallApi])

    useEffect(() => {
        if (callApiBuy) {
            if(idMercSelected && idWeaponSelected){
                buyWeapon(idMercSelected, idWeaponSelected, setInfo, setCallApi)
            }
            else {
                setInfo("Uncomplete selection")
            }
            setCallApiBuy(false)
        }
    }, [callApiBuy, setCallApiBuy, idMercSelected, idWeaponSelected, setInfo, setCallApi])

    const displayMercs = () => {
        let html = []
        mercs.forEach(merc => {
            if (merc.isAlive) {
                html.push(<Grid key={"launch-merc-" + merc.id} item xs={11}><StoreMercs setSelect={setIdMercSelected} id={merc.id} nickname={merc.nickname} legalAge={merc.legalAge} idWeapon={merc.idWeapon} weaponName={merc.weaponName} eddies={merc.eddies} isAlive={merc.isAlive} isSelected={idMercSelected === merc.id ? true : false}></StoreMercs></Grid>)
            }
        })
        if (html.length === 0) {
            html = <Grid item xs={11}><CyberPanel content={<p className={"info"}>No mercenary alive...</p>}></CyberPanel></Grid>
        }
        return html
    }

    const displayWeapons = () => {
        let html = []
        weapons.forEach(weapon => {
            html.push(<Grid key={"store-weapon-" + weapon.id} item xs={11}><StoreWeapons setSelect={setIdWeaponSelected} id={weapon.id} name={weapon.name} description={weapon.description} damage={weapon.damage} accuracy={weapon.accuracy} firerate={weapon.firerate} price={weapon.price} isSelected={idWeaponSelected === weapon.id ? true : false}></StoreWeapons></Grid>)
        })
        if (html.length === 0) {
            html = <Grid item xs={11}><CyberPanel content={<p className={"info"}>No job available...</p>}></CyberPanel></Grid>
        }
        return html
    }

    return (
        <CyberPanel legend={"Store"} content={
            <div className="content no-scroll">
                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={5}>
                        <CyberPanel legend={"Mercs"} content={
                            <div className="content-2">
                                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                                    {displayMercs()}
                                </Grid>
                            </div>
                        }></CyberPanel>
                    </Grid>
                    <Grid item xs={12} md={2} >
                        <button className={"cyber-button-2"} onClick={e => setCallApiBuy(true)}> Buy weapon</button>
                        <p className="info">{info}</p>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <CyberPanel legend={"Weapons"} content={
                            <div className="content-2">
                                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                                    {displayWeapons()}
                                </Grid>
                            </div>
                        }></CyberPanel>
                    </Grid>
                </Grid>
            </div>
        }>
        </CyberPanel>
    )
}

export default Store
import CyberPanel from '../../cyber-panel';
import Grid from '@material-ui/core/Grid';
import MercsData from '../mercs-data';
import MercsCreate from '../mercs-create';
import { getMercs } from '../../../api/callApi';
import { useState, useEffect } from 'react';


const Mercs = () => {

    const [mercs, setMercs] = useState([])
    const [info, setInfo] = useState("")
    const [callApi, setCallApi] = useState(true)

    useEffect(() => {
        if (callApi) {
            getMercs(null, setInfo, setMercs)
            setCallApi(false)
        }
    }, [callApi, setInfo, setMercs, setCallApi])

    return (
        <CyberPanel legend={"Mercs"} content={
            <div className="content">
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={11}>
                        <MercsCreate launchRefresh={setCallApi}></MercsCreate>
                    </Grid>
                    {mercs.map((merc => <Grid key={"merc-" + merc.id} item xs={11} md={5}><MercsData id={merc.id} nickname={merc.nickname} legalAge={merc.legalAge} idWeapon={merc.idWeapon} weaponName={merc.weaponName} eddies={merc.eddies} isAlive={merc.isAlive}></MercsData></Grid>))}
                    <Grid item xs={11} md={5}> <p className={"info"}>{info}</p> </Grid>
                </Grid>
            </div>
        }>
        </CyberPanel>
    )
}


export default Mercs
import Grid from '@material-ui/core/Grid';
import CyberPanel from '../cyber-panel';
import CyberMenu from '../cyber-menu';
import CyberLogo from '../cyber-logo';
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import Merc from '../content/mercs';
import Jobs from '../content/jobs';
import Weapons from '../content/weapons';
import Store from '../content/store';
import LaunchMission from '../content/launch-mission';
import Error404 from '../content/error-404';
import { FormControlLabel, Switch as SwitchMaterial } from '@material-ui/core';
import { useState, useEffect } from 'react';


const App = () => {
    const [music, setMusic] = useState(false)

    useEffect(() => {
        if (music) {
            document.getElementById("music-audio").play()
        }
        else {
            document.getElementById("music-audio").pause()
        }
    }, [music])


    return (
        <div className="app">
            <BrowserRouter>
                <Grid container spacing={1}>
                    <Grid item xs={10}>
                        <FormControlLabel
                            control={<SwitchMaterial id="music-switch" name="switch-music" onChange={(e) => setMusic(e.target.checked)} />}
                            label="Music"
                        />
                        <audio id="music-audio" loop><source src={"./media/music.mp3"} type="audio/mpeg" /></audio>
                    </Grid>
                    <Grid item xs={2}>
                        <Link to="/404">
                            <button className="cyber-glitch">ACC.E?S T0 MI?K.OSHI</button>
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                        <CyberLogo></CyberLogo>
                    </Grid>
                    <Grid item xs={4}>
                        <CyberPanel legend={"Menu"} content={<CyberMenu></CyberMenu>}></CyberPanel>
                    </Grid>
                    <Grid item xs={8}>
                        <Switch>
                            <Route exact path="/" component={Merc} />
                            <Route path="/mercs" component={Merc} />
                            <Route path="/jobs" component={Jobs} />
                            <Route path="/weapons" component={Weapons} />
                            <Route path="/store" component={Store} />
                            <Route path="/launch-mission" component={LaunchMission} />
                            <Route path="*" component={Error404} />
                        </Switch>
                    </Grid>
                </Grid>
            </BrowserRouter>
        </div>
    );
}


export default App;
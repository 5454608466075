import { Link } from "react-router-dom";
import { useState } from 'react';


const CyberMenu = () => {

  const [activeButtonMenu, setActiveButtonMenu] = useState(0)
  const listOfButtons = [
    { link: "/mercs", text: "Mercs" },
    { link: "/jobs", text: "Jobs" },
    { link: "/weapons", text: "Weapons" },
    { link: "/store", text: "Store" },
    { link: "/launch-mission", text: "Launch Mission" }
  ]

  return (

    <div className="cyber-menu">
      {listOfButtons.map((btn, index) => <Link key={"menu-" + index} to={btn.link}><button onClick={e => setActiveButtonMenu(index)} className={activeButtonMenu === index ? "cyber-button cyber-button-active" : "cyber-button"}> {btn.text} </button></Link>)}
    </div>
  )
}


export default CyberMenu
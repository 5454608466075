import Axios from 'axios'

const rootUrl = "https://cyberpunk-api.bastienmarais.fr"

export const createJob = async (job, setInfo, launchRefresh) => {
    try {
        const url = `${rootUrl}/job/create`
        const response = await Axios.post(url, job)
        if (response.status === 200) {
            setInfo("Job created")
            launchRefresh(true)
        }
    }
    catch (e) {
        if (e?.response?.data) {
            setInfo(e.response.data)
        }
        else {
            setInfo(e.message)
        }
    }
}

export const getJobs = async (filter, setInfo, setJobs) => {
    try {
        let url = `${rootUrl}/jobs/`
        if (filter) {
            url += filter
        }
        const response = await Axios.get(url)
        if (response.status === 200) {
            setJobs(response.data)
        }
    }
    catch (e) {
        if (e?.response?.data) {
            setInfo(e.response.data)
        }
        else {
            setInfo(e.message)
        }
    }
}

export const createWeapon = async (weapon, setInfo, launchRefresh) => {
    try {
        const url = `${rootUrl}/weapon/create`
        const response = await Axios.post(url, weapon)
        if (response.status === 200) {
            setInfo("Weapon created")
            launchRefresh(true)
        }
    }
    catch (e) {
        if (e?.response?.data) {
            setInfo(e.response.data)
        }
        else {
            setInfo(e.message)
        }
    }
}

export const getWeapons = async (filter, setInfo, setWeapons) => {
    try {
        let url = `${rootUrl}/weapons/`
        if (filter) {
            url += filter
        }
        const response = await Axios.get(url)
        if (response.status === 200) {
            setWeapons(response.data)
        }
    }
    catch (e) {
        if (e?.response?.data) {
            setInfo(e.response.data)
        }
        else {
            setInfo(e.message)
        }
    }
}


export const createMerc = async (merc, setInfo, launchRefresh) => {
    try {
        const url = `${rootUrl}/merc/create`
        const response = await Axios.post(url, merc)
        if (response.status === 200) {
            setInfo("Merc created")
            launchRefresh(true)
        }
    }
    catch (e) {
        if (e?.response?.data) {
            setInfo(e.response.data)
        }
        else {
            setInfo(e.message)
        }
    }
}

export const getMercs = async (filter, setInfo, setMercs) => {
    try {
        let url = `${rootUrl}/mercs/`
        if (filter) {
            url += filter
        }
        const response = await Axios.get(url)
        if (response.status === 200) {
            setMercs(response.data)
        }
    }
    catch (e) {
        if (e?.response?.data) {
            setInfo(e.response.data)
        }
        else {
            setInfo(e.message)
        }
    }
}

export const launchMission = async (jobId, mercId, setInfo, setReport, launchRefresh) => {
    try {
        const url = `${rootUrl}/job/start/${jobId}/${mercId}`
        const response = await Axios.post(url)
        if (response.status === 200) {
            setInfo("Successful mission launch")
            setReport(response.data)
            launchRefresh(true)
        }
    }
    catch (e) {
        if (e?.response?.data) {
            setInfo(e.response.data)
        }
        else {
            setInfo(e.message)
        }
    }
}

export const buyWeapon = async (mercId, weaponId, setInfo, launchRefresh) => {
    try {
        const url = `${rootUrl}/weapon/buy/${mercId}/${weaponId}`
        const response = await Axios.post(url)
        if (response.status === 200) {
            setInfo("Purchase done")
            launchRefresh(true)
        }
    }
    catch (e) {
        if (e?.response?.data) {
            setInfo(e.response.data)
        }
        else {
            setInfo(e.message)
        }
    }
}

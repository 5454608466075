import CyberPanel from '../../cyber-panel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useState, useEffect } from 'react';
import { createJob } from '../../../api/callApi'

const JobsCreate = ({ launchRefresh }) => {

    const [fixer, setFixer] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [henchmenCount, setHenchmenCount] = useState("")
    const [reward, setReward] = useState("")
    const [callApi, setCallApi] = useState(false)
    const [info, setInfo] = useState("")

    useEffect(() => {
        if (callApi) {
            const job = {
                fixer: fixer,
                title: title,
                description: description,
                henchmenCount: henchmenCount,
                reward: reward
            }
            createJob(job, setInfo, launchRefresh)
            setCallApi(false)
        }
    }, [callApi, fixer, title, description, henchmenCount, reward, setInfo, setCallApi, launchRefresh])

    return (
        <CyberPanel content={
            <Grid container justify={"center"} spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField required label="Fixer" variant="outlined" fullWidth value={fixer} onChange={e => setFixer(e.target.value)} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField required label="Title" variant="outlined" fullWidth value={title} onChange={e => setTitle(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField multiline={true} required label="Description" variant="outlined" fullWidth value={description} onChange={e => setDescription(e.target.value)} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField required label="Henchmen Count" variant="outlined" fullWidth value={henchmenCount} onChange={e => setHenchmenCount(e.target.value)} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField required label="Reward" variant="outlined" fullWidth value={reward} onChange={e => setReward(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <button className={"cyber-button-2"} onClick={e => setCallApi(true)}> Declare a new job</button>
                </Grid>
                <Grid item xs={12}>
                    <p className="info">{info}</p>
                </Grid>
            </Grid>
        }></CyberPanel>
    )
}


export default JobsCreate
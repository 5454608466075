import CyberPanel from '../../cyber-panel';
import Grid from '@material-ui/core/Grid';
import JobsData from '../jobs-data';
import JobsCreate from '../jobs-create';
import { getJobs } from '../../../api/callApi';
import { useState, useEffect } from 'react';

const Jobs = () => {

    const [jobs, setJobs] = useState([])
    const [info, setInfo] = useState("")
    const [callApi, setCallApi] = useState(true)

    useEffect(() => {
        if (callApi) {
            getJobs(null, setInfo, setJobs)
            setCallApi(false)
        }
    }, [callApi, setInfo, setJobs, setCallApi])


    return (
        <CyberPanel legend={"Jobs"} content={
            <div className="content">
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={11}>
                        <JobsCreate launchRefresh={setCallApi}></JobsCreate>
                    </Grid>
                    {jobs.map((job => <Grid key={"job-" + job.id} item xs={11} md={5}><JobsData id={job.id} fixer={job.fixer} title={job.title} description={job.description} henchmenCount={job.henchmenCount} reward={job.reward} isAvailable={job.isAvailable}></JobsData></Grid>))}
                    <Grid item xs={11} md={5}> <p className={"info"}>{info}</p></Grid>
                </Grid>
            </div>
        }>
        </CyberPanel>
    )
}


export default Jobs
import CyberPanel from '../../cyber-panel';
import Grid from '@material-ui/core/Grid';
import WeaponsData from '../weapons-data';
import WeaponsCreate from '../weapons-create';
import { getWeapons } from '../../../api/callApi';
import { useState, useEffect } from 'react'

const Weapons = () => {

    const [weapons, setWeapons] = useState([])
    const [info, setInfo] = useState("")
    const [callApi, setCallApi] = useState(true)

    useEffect(() => {
        if (callApi) {
            getWeapons(null, setInfo, setWeapons)
            setCallApi(false)
        }
    }, [callApi, setInfo, setWeapons, setCallApi])


    return (
        <CyberPanel legend={"Weapons"} content={
            <div className="content">
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={11}>
                        <WeaponsCreate launchRefresh={setCallApi}></WeaponsCreate>
                    </Grid>
                    {weapons.map((weapon => <Grid key={"weapon-" + weapon.id} item xs={11} md={5}><WeaponsData id={weapon.id} name={weapon.name} description={weapon.description} damage={weapon.damage} accuracy={weapon.accuracy} firerate={weapon.firerate} price={weapon.price}></WeaponsData></Grid>))}
                    <Grid item xs={11} md={5}> <p className={"info"}>{info}</p></Grid>
                </Grid>
            </div>
        }>
        </CyberPanel>
    )
}

export default Weapons
import CyberPanel from '../../cyber-panel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const MercsData = ({ id, nickname, legalAge, idWeapon, weaponName, eddies, isAlive }) => {

    return (
        <CyberPanel legend={"#" + id} content={
            <Grid container spacing={2} className={isAlive ? "" : "dead-bg"}>
                <Grid item xs={12} md={6}>
                    <TextField disabled label="Nickname" variant="outlined" value={nickname} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField disabled label="Age" variant="outlined" value={legalAge} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField disabled label="Weapon" variant="outlined" value={weaponName} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField disabled label="Eddies" variant="outlined" value={eddies} fullWidth />
                </Grid>
            </Grid>
        }></CyberPanel>
    )
}


export default MercsData
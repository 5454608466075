import CyberPanel from '../../cyber-panel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const StoreMercs = ({ id, nickname, legalAge, idWeapon, weaponName, eddies, isAlive, isSelected, setSelect }) => {

    return (
        <CyberPanel isSelected={isSelected} legend={"#" + id} content={
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField disabled label="Nickname" variant="outlined" defaultValue={nickname} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField disabled label="Age" variant="outlined" defaultValue={legalAge} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField disabled label="Weapon" variant="outlined" defaultValue={weaponName} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField disabled label="Eddies" variant="outlined" defaultValue={eddies} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <button className={"cyber-button-2"} onClick={e => setSelect(id)}> Select this merc</button>
                </Grid>
            </Grid>
        }></CyberPanel>
    )
}


export default StoreMercs